import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { errorHandler } from '@/core/libs/error-handler';
import { SearchQuery } from '@/modules/matchmaking/models/searchbar/SearchQuery';

import { UseSearchResults, useSearchResults } from '../hooks/useSearchResults';
import { searchQueryToUrl, urlToSearchQuery } from '../utils';

export interface SearchContext extends Omit<UseSearchResults, 'fetch'> {
  searchQuery: SearchQuery;
  searchQueryURL: string;
  page: number;
  setSearchQuery(query: SearchQuery): void;
  setSearchQuery(callback: (query: SearchQuery) => SearchQuery): void;
  setPage(page: number): void;
}

const SearchContext = createContext<SearchContext>({
  results: [],
  suggestedResults: [],
  totalManufacturers: 0,
  totalMatching: 0,
  isMatchingMachines: false,
  loading: false,
  pageSize: 10,
  searchQuery: {},
  searchQueryURL: '',
  page: 0,
  setSearchQuery: () => {
    errorHandler.capture(
      'setSearchQuery() being used without initializing SearchQuery context',
      {
        avoidFlashMessage: true,
      },
    );
    throw Error();
  },
  setPage: () => {
    errorHandler.capture(
      'setPage() being used without initializing SearchQuery context',
      { avoidFlashMessage: true },
    );
    throw Error();
  },
});

export interface SearchQueryProviderProps {
  children: JSX.Element;
  autoFetch?: boolean;
}

export function SearchProvider({
  children,
}: SearchQueryProviderProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const { currentUser } = useAuthenticationContext();
  const getQryUrlFromPath = (): string =>
    location.pathname.split('/search/')[1];
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(
    urlToSearchQuery(getQryUrlFromPath()),
  );
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetch, ...searchResults } = useSearchResults();
  const { '*': urlQuery } = useParams();
  const getQueryURL = (): string => searchQueryToUrl(searchQuery);
  const page = useMemo<number>(() => {
    let finalPage = 1;
    const pageParam = searchParams.get('p');
    if (pageParam) {
      try {
        const pageInt = parseInt(pageParam);
        if (!Number.isNaN(pageInt)) {
          finalPage = pageInt;
        }
      } catch {
        finalPage = 1;
      }
    }
    return finalPage;
  }, [searchParams]);
  const setPage = useCallback(
    (newPage: number) => {
      setSearchParams({ p: newPage.toString() });
    },
    [setSearchParams],
  );
  const fetchMans = async (query: SearchQuery = searchQuery): Promise<void> => {
    await fetch(query as SearchQuery, page);
  };
  const fetchMansAndNavigate = async (query: SearchQuery): Promise<void> => {
    if (isFetching) return;

    setIsFetching(true);
    const urlFromQry = getQueryURL();
    navigate(`/search/${urlFromQry}?p=${page}`, {
      replace: !!urlQuery && !!urlFromQry,
    });
    await fetchMans(query);
    setIsFetching(false);
  };

  useEffect(() => {
    const qryUrlFromPath = getQryUrlFromPath();
    const newQry = urlToSearchQuery(qryUrlFromPath);

    if (!qryUrlFromPath) {
      fetchMans({});
    } else {
      fetchMans(newQry);
    }
  }, [location, navigationType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMansAndNavigate(searchQuery);
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMans(searchQuery);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMans();
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        page,
        setPage,
        searchQueryURL: getQueryURL(),
        ...searchResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearchContext(): SearchContext {
  return useContext(SearchContext);
}
