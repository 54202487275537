export enum IconType {
  FULLSCREEN = 'of-icon-full-screen',
  VOLUME_BAN = 'of-icon-volume-ban',
  VOLUME = 'of-icon-volume-97',
  DESKTOP_SCREEN = 'of-icon-desktop-screen-1',
  WEBCAM = 'of-icon-webcam-38',
  AUDIO = 'of-icon-audio-91',
  RECORD = 'of-icon-button-record',
  PANNING = 'of-icon-zoom-99',
  PLAY = 'of-icon-button-play-1',
  PLAY_FILLED = 'of-icon-button-play-2',
  PLAY_CIRCLED = 'of-icon-button-circle-play',
  CAMERA = 'of-icon-camera-18',
  CHAT = 'of-icon-chat-46-1',
  EMAIL = 'of-icon-email-84-1',
  EMAIL_FILLED = 'of-icon-email-84-2',
  SEND_CHAT = 'of-icon-send-chat',
  SEND_CHAT_FILLED = 'of-icon-send-chat-2',
  BELL = 'of-icon-bell-on',
  BELL_OFF = 'of-icon-bell-off',
  PHONE = 'of-icon-phone',
  MESSAGE = 'of-icon-a-chat',
  CALENDAR = 'of-icon-calendar-60',
  CALENDAR_CHECKED = 'of-icon-calendar-check-62',
  CALENDAR_ADD = 'of-icon-calendar-add',
  CALENDAR_GRID = 'of-icon-calendar-grid-61',
  HOURGLASS = 'of-icon-hourglass',
  TIME_CLOCK = 'of-icon-time-clock',
  TIME_MACHINE = 'of-icon-time-machine',
  PIN_CHECKED = 'of-icon-pin-check',
  PIN = 'of-icon-pin-3-1',
  PIN_FILLED = 'of-icon-pin-3-2',
  WORLD = 'of-icon-world',
  WORLD_FILLED = 'of-icon-world-2',
  WORLD_WITH_PIN = 'of-icon-world-marker',
  MAP_WITH_PIN = 'of-icon-map-pin',
  MAP = 'of-icon-map',
  NUMBER_1 = 'of-icon-nr-1',
  NUMBER_2 = 'of-icon-nr-2',
  NUMBER_3 = 'of-icon-nr-3',
  NUMBER_4 = 'of-icon-nr-4',
  NUMBER_5 = 'of-icon-nr-5',
  STP_FILE = 'of-icon-stp-file',
  PNG_FILE = 'of-icon-png-file',
  PDF_FILE = 'of-icon-pdf-file',
  JPG_FILE = 'of-icon-jpg-file',
  GIF_FILE = 'of-icon-gif-file',
  DXF_FILE = 'of-icon-dxf-file',
  DWG_FILE = 'of-icon-dwg-file',
  IPT_FILE = 'of-icon-ipt-file',
  DOCX_FILE = 'of-icon-docx-file',
  STEP_FILE = 'of-icon-step-file',
  CATIA_FILE = 'of-icon-catia-file',
  RVT_FILE = 'of-icon-rtv-file',
  F3D_FILE = 'of-icon-f3d-file',
  XLSX_FILE = 'of-icon-xlsx-file',
  FILE = 'of-icon-single-folded',
  LAYERS = 'of-icon-layers-3',
  DOWNLOAD = 'of-icon-square-download',
  UPLOAD = 'of-icon-square-upload',
  FOLDER_CHECKED = 'of-icon-folder-check',
  ATTACHMENT = 'of-icon-attach-86',
  CLOUD = 'of-icon-cloud-forecast',
  X_CROSS = 'of-icon-simple-remove',
  ARROW_DOWN = 'of-icon-minimal-down',
  ARROW_LEFT = 'of-icon-minimal-left',
  ARROW_RIGHT = 'of-icon-minimal-right',
  ARROW_UP = 'of-icon-minimal-up',
  BURGER_MENU = 'of-icon-menu-34',
  LOGOUT = 'of-icon-log-out',
  LOGIN = 'of-icon-log-in',
  USER_ADD = 'of-icon-add-29',
  USER = 'of-icon-single-01-1',
  MINIMIZE_WINDOW = 'of-icon-window-zoom-out',
  CHECK_CIRCLE = 'of-icon-check-circle-08-1',
  CHECK_CIRCLE_FILLED = 'of-icon-check-circle-08-2',
  CHECK = 'of-icon-check-2',
  FUNNEL = 'of-icon-funnel-40-1',
  FUNNEL_FILLED = 'of-icon-funnel-40-2',
  ALERT_CIRCLED = 'of-icon-alert-circle-i',
  ZOOM = 'of-icon-zoom-2',
  LOCK = 'of-icon-lock-1',
  UNLOCK = 'of-icon-lock-open-1',
  UNLOCK_FILLED = 'of-icon-lock-open-2',
  LOCK_FILLED = 'of-icon-lock-2',
  SAVE = 'of-icon-disk-2',
  STAR = 'of-icon-shape-star-1',
  STAR_FILLED = 'of-icon-shape-star-2',
  QUESTION = 'of-icon-alert-circle-que',
  PEN = 'of-icon-pen-01-1',
  PEN_FILLED = 'of-icon-pen-01-2',
  ALERT = 'of-icon-alert-1',
  ALERT_FILLED = 'of-icon-alert-2',
  TRASH = 'of-icon-trash-simple-2',
  TRIANGLE_DOWN = 'of-icon-triangle-down-20-1',
  TRIANGLE_DOWN_FILLED = 'of-icon-triangle-down-20-2',
  TRIANGLE_UP = 'of-icon-triangle-up-19-1',
  TRIANGLE_UP_FILLED = 'of-icon-triangle-up-19-2',
  UNGROUP = 'of-icon-ungroup',
  REFRESH = 'of-icon-refresh-01',
  FORBIDDEN = 'of-icon-ban-bold-1',
  FORBIDDEN_FILLED = 'of-icon-ban-bold-2',
  EDIT = 'of-icon-edit-75',
  ADD = 'of-icon-simple-add',
  ADD_SMALL = 'of-icon-small-add',
  ADD_SQUARE = 'of-icon-s-add',
  SETTINGS = 'of-icon-settings-gear-63',
  COLUMNS = 'of-icon-column-view',
  KEBAB_MENU = 'of-icon-menu-dots-vertical',
  KEBAB_MENU_FILLED = 'of-icon-menu-dots-2-vertical',
  HORIZONTAL_DRAG = 'of-icon-drag',
  PREFERENCES = 'of-icon-preferences',
  USER_FILLED = 'of-icon-single-01-2',
  SEARCH = 'of-icon-search-2',
  HIDE = 'of-icon-eye-ban-18',
  SHOW = 'of-icon-eye-17',
  COMPARE_ARROWS = 'of-icon-compare',
  DELETE = 'of-icon-simple-delete',
  DELETE_SMALL = 'of-icon-small-delete',
  ROTATE_LEFT = 'of-icon-rotate-left-2',
  ROTATE_RIGHT = 'of-icon-rotate-right-2',
  FLIP_VERTICAL = 'of-icon-flip-vertical',
  FLIP_HORIZONTAL = 'of-icon-flip-horizontal',
  MERGE = 'of-icon-unite-round',
  RESIZE = 'of-icon-resize-vertical',
  RESIZE_SMALL = 'of-icon-resize-vertical-small',
  QUOTE = 'of-icon-quote-1',
  QUOTE_FILLED = 'of-icon-quote-2',
  TAILED_ARROW_UP = 'of-icon-tail-up',
  TAILED_ARROW_DOWN = 'of-icon-tail-down',
  TAILED_ARROW_RIGHT = 'of-icon-tail-right',
  TAILED_ARROW_LEFT = 'of-icon-tail-left',
  DOUBLE_CHECK = 'of-icon-check-double',
  PERSPECTIVE_3D = 'of-icon-3d-29',
  SIGN = 'of-icon-sign',
  RULER_PENCIL = 'of-icon-ruler-pencil',
  ADD_CIRCLED = 'of-icon-c-add-1',
  ADD_CIRCLED_FILLED = 'of-icon-c-add-2',
  REMOVE_CIRCLED = 'of-icon-c-remove-2',
  REMOVE_CIRCLED_FILLED = 'of-icon-c-remove-1',
  MEATBALL_MENU = 'of-icon-menu-dots',
  CREDIT_CARD = 'of-icon-credit-card',
  ALIPAY = 'of-icon-alipay',
  CHEQUE = 'of-icon-cheque',
  CHECKBOX = 'of-icon-i-check',
  SIDEBAR = 'of-icon-sidebar',
  A_STAR = 'of-icon-a-star',
  RECEIPT = 'of-icon-receipt',
  MONEY_TIME = 'of-icon-money-time',
  PAGE_SEARCH = 'of-icon-p-search',
  STAR_HALF_FILLED = 'of-icon-shape-star-2-half',
  BOOKMARK_ADD = 'of-icon-bookmark-add',
  BOOKMARK_DELETE_2 = 'of-icon-bookmark-delete-2',
  BOOKMARK_ADD_2 = 'of-icon-bookmark-add-2',
  FLAG = 'of-icon-flag-points-31',
  LINK = 'of-icon-link',
  PRIVACY = 'of-icon-privacy',
  GRID = 'of-icon-grid-view',
  CROP = 'of-icon-crop',
  FLASH = 'of-icon-camera-flash',
  HANDSHAKE = 'of-icon-handshake',
  SORT = 'of-icon-ic_sort_24px-2',
  INFORMATION = 'of-icon-c-info',
  COMPARE = 'of-icon-compare-2',
  NEXT = 'of-icon-next',
  PREVIOUS = 'of-icon-previous',
  BOOKMARK = 'of-icon-bookmark',
  LINKEDIN = 'of-icon-logo-linkedin',
  TWITTER = 'of-icon-logo-twitter',
  YOUTUBE = 'of-icon-logo-youtube',
  INSTAGRAM = 'of-icon-logo-instagram',
  FACEBOOK = 'of-icon-logo-fb-simple',
  FACEBOOK_SQUARE = 'of-icon-logo-facebook',
  XING_SQUARE = 'of-icon-logo-xing',
  XING = 'of-icon-logo-xing-glyph',
  YOUKU = 'of-icon-icon-youku-glyph',
  YOUKU_SQUARE = 'of-icon-icon-youku',
  LIKE = 'of-icon-like-1',
  DISLIKE = 'of-icon-dislike-1',
  LIKE_FILLED = 'of-icon-like-2',
  DISLIKE_FILLED = 'of-icon-dislike-2',
  WECHAT = 'of-icon-logo-wechat',
  DASHBOARD = 'of-icon-dashboard-half',
  CONTENT = 'of-icon-single-content-03',
  COPY = 'of-icon-single-copy-04',
  LIST = 'of-icon-list',
  ARCHIVE = 'of-icon-archive-paper',
  ARCHIVE_CHECKED = 'of-icon-archive-paper-check',
  PAPER = 'of-icon-paper-2',
  BRIEFCASE = 'of-icon-briefcase-24',
  STORE = 'of-icon-store',
  ATM = 'of-icon-atm',
  BULB = 'of-icon-bulb-63',
  BUILDING = 'of-icon-building',
  USER_MULTIPLE = 'of-icon-multiple',
  WIFI = 'of-icon-wifi-2',
  TEXTURE = 'of-icon-texture',
  FACTORY = 'of-icon-factory',
  AWARD = 'of-icon-award-49',
  USER_GROUP = 'of-icon-multiple-11',
  SIGNAL = 'of-icon-signal-2',
  CHART_UP = 'of-icon-chart-bar-33',
  SENSOR = 'of-icon-sensor',
  ATOM = 'of-icon-atom',
  VERIFIED = 'of-icon-verified',
  PICTURE = 'of-icon-stock',
  PIE_CHART = 'of-icon-chart',
  CHART = 'of-icon-chart-bar-32',
  BULLET_LIST = 'of-icon-bullet-list-70',
  CNC_MACHINE = 'of-icon-cnc-machine',
  HOME = 'of-icon-home-52',
  HOME_FILLED = 'of-icon-house-glyph',
  SUPPORT = 'of-icon-support-16',
  COINS = 'of-icon-coins',
  NODE = 'of-icon-node',
  DELIVERY_TIME = 'of-icon-delivery-time',
  WINDOW = 'of-icon-window-paragraph',
  TICKET = 'of-icon-ticket',
  NETWORK = 'of-icon-network-connection',
  PRINT = 'of-icon-print',
  DATA = 'of-icon-data-table',
  GRID_INTERFACE = 'of-icon-grid-interface',
  DOC_FOLDER = 'of-icon-doc-folder',
  NEWSLETTER = 'of-icon-newsletter',
  BLUEPRINT = 'of-icon-blueprint',
  DIRECTION_UP = 'of-icon-direction-up',
  DIRECTION_DOWN = 'of-icon-direction-down',
  DIRECTION_UP_FILLED = 'of-icon-direction-up-1',
  DIRECTION_DOWN_FILLED = 'of-icon-direction-down-1',
  FILE_REPLACE = 'of-icon-file-replace',
  BUSINESS_CARD = 'of-icon-badge-14',
  BILL = 'of-icon-bill',
  PULSE = 'of-icon-pulse',
  CALENDAR_2 = 'of-icon-calendar-date-2',
  CIRCLE_CHECK = 'of-icon-n-check',
  CIRCLE_CHECK_EMPTY = 'of-icon-n-check-empty',
  RELOAD = 'of-icon-reload',
  TREND_UP = 'of-icon-trend-up',
  TREND_DOWN = 'of-icon-trend-down',
  GHOST = 'of-icon-ghost',
  GHOST_PACMAN = 'of-icon-ghost-2',
  TRANSLATION = 'of-icon-translation',
  CIRCLE_ANIM = 'of-icon-circle-anim',
  CIRCLE = 'of-icon-circle',
  BOLT = 'of-icon-bolt',
  CHECK_SMALL = 'of-icon-check-small',
  IMG = 'of-icon-img',
  IMG_1 = 'of-icon-img-1',
  WIDTH = 'of-icon-width',
  HEIGHT_2 = 'of-icon-height-2',
  DEPTH = 'of-icon-depth',
  SHAPE_OVAL = 'of-icon-shape-oval',
  SPARKS = 'of-icon-sparks',
  SPARKS_1 = 'of-icon-sparks-1',
  TIMELINE = 'of-icon-timeline',
  FILE_COPIES = 'of-icon-file-copies',
  BLOCK = 'of-icon-block',
  PUZZLE_TOY = 'of-icon-puzzle-toy',
  HASH_MARK = 'of-icon-hash-mark',
  SETUP_PREFERENCES = 'of-icon-setup-preferences',
  OPEN_NEW_TAB = 'of-icon-open-new-tab',
  ROW_TABLE = 'of-icon-row-table',
  CARD_LAYOUT = 'of-icon-card-layout-1',
  SWAP_VERTICAL = 'of-icon-swap-vertical',
  SELECTED_REQUESTS = 'of-icon-selected-requests',
  RESEARCH = 'of-icon-research',
  NDA = 'of-icon-nda',
  ARROW_SM_DOWN = 'of-icon-arrow-sm-down',
  CARET_SM_UP = 'of-icon-caret-sm-up',
  ARROW_SM_LEFT = 'of-icon-arrow-sm-left',
  ARROW_SM_RIGHT = 'of-icon-arrow-sm-right',
  CHECKBOX_BTN = 'of-icon-checkbox-btn',
  BOLD = 'of-icon-bold',
  TEXT_ITALIC = 'of-icon-text-italic',
  UNDERLINE = 'of-icon-underline',
  ORDERED_LIST = 'of-icon-ordered-list',
  UNORDERED_LIST = 'of-icon-unordered-list',
  PLACEHOLDER = 'of-icon-placeholder',
  USER_STAR = 'of-icon-user-star',
  AUTOMATED_LOGISTICS = 'of-icon-automated-logistics',
  BAN = 'of-icon-ban',
  LIGHTNING = 'of-icon-lightning',
}
